import React from "react";
import FormPage from "./FormPage";
import "./requestdemo.css";
import WhyUse from "./WhyUse";
import MarketingCampaings from "./MarketingCampaings";
import Leading from "./Leading";
import axios from "axios";
import { useQuery } from "react-query";
import { SITETITLE } from "../../constants";
import InfoBlock from "../../components/core/info-block/InfoBlock";
import { useLocation } from "react-router-dom"; // Import the useLocation hook
import Loading from "../../components/global/Loading";
import { Helmet } from "react-helmet";

const RequestDemo = () => {
  const location = useLocation(); // Get the current location object
  const queryParams = new URLSearchParams(location.search); // Create a URLSearchParams object from the query string
  const featureName = queryParams.get("featureName"); // Get the value of the "featureName" query parameter

  const getFeaturedListData = async ({ signal }) => {
    try {
      const response = await axios.get(
        window.apiBaseUrl +
          `request-demo/sub-features?featureName=${featureName}`,
        { signal }
      );
      return response.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        throw error; // Re-throw if not canceled
      }
    }
  };

  // header banner
  const getHeaderData = async ({ signal }) => {
    try {
      const response = await axios.get(
        window.apiBaseUrl + `request-demo?featureName=${featureName}`,
        { signal }
      );
      return response.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        throw error; // Re-throw if not canceled
      }
    }
  };
  // // Marketing Campign
  const getMarketingCampaignData = async ({ signal }) => {
    try {
      const response = await axios.get(
        window.apiBaseUrl +
          `request-demo/marketing-campaign?featureName=${featureName}`,
        { signal }
      );

      return response.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        throw error; // Re-throw if not canceled
      }
    }
  };

  // //  Leading Global
  const getLeadingGlobalData = async ({ signal }) => {
    try {
      const response = await axios.get(
        window.apiBaseUrl + `home/testimonials?featurePage=cosmos-iq-business`,
        { signal }
      );
      return response.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        throw error; // Re-throw if not canceled
      }
    }
  };

  // // why Use
  const getWhyUseCosmosData = async ({ signal }) => {
    try {
      const response = await axios.get(
        window.apiBaseUrl +
          `request-demo/why-use-cosmos?featureName=${featureName}`,
        { signal }
      );
      return response.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        throw error; // Re-throw if not canceled
      }
    }
  };

  const { data: headerData, isLoading: headerLoading } = useQuery(
    "getHeaderData",
    getHeaderData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  const { data: featuredListData, isLoading: featureLoading } = useQuery(
    "enterpriseFeaturedList",
    getFeaturedListData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  const { data: marketingCampaignData, isLoading: marketingLoading } = useQuery(
    "marketingCampaign",
    getMarketingCampaignData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  const { data: leadingData, isLoading: leadingLoading } = useQuery(
    "getLeadingData",
    getLeadingGlobalData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  const { data: whyUseCosmos, isLoading: whyUseCosmosLoading } = useQuery(
    "getWhyUseCosmosData",
    getWhyUseCosmosData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  // if (error) return <div>An error occurred: {error.message}</div>;
  if (
    headerLoading ||
    marketingLoading ||
    leadingLoading ||
    whyUseCosmosLoading ||
    featureLoading
  )
    return <Loading />;

  return (
    <>
      <Helmet>
        <title>{headerData ? headerData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={headerData ? headerData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={headerData ? headerData.metaKeywords : SITETITLE}
        />
      </Helmet>
      {/* HeaderBanner */}
      {headerData ? (
        <>
          <FormPage data={headerData} />
        </>
      ) : (
        ""
      )}

      {whyUseCosmos ? (
        <>
          <WhyUse HeaderData={headerData} CardData={whyUseCosmos.list} />
        </>
      ) : (
        ""
      )}

      {featuredListData && featuredListData.list ? (
        <>
          {featuredListData.list.map((list, index) => (
            <InfoBlock
              key={index + 1}
              imgUrl={list.featuredImagePath}
              subtitle={list.heading}
              title={list.subHeading}
              description={list.description}
              buttonsOne={list.buttonsOne}
              buttonsLinkOne={list.buttonsLinkOne}
              buttonsTwo={list.buttonsTwo}
              buttonsLinkTwo={list.buttonsLinkTwo}
              featureName={list.featuredName}
              number={index + 1}
            />
          ))}
        </>
      ) : (
        ""
      )}

      {marketingCampaignData ? (
        <>
          <MarketingCampaings
            HeaderData={headerData}
            data={marketingCampaignData.list}
          />
        </>
      ) : (
        ""
      )}

      {leadingData ? (
        <>
          <Leading
            HeaderData={leadingData.staticData}
            LeadingData={leadingData.list}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default RequestDemo;

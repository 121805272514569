import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

import {
  haresh,
  sanjeev,
  sunil,
  atulNaik,
  runali,
  riyaz,
  pooja,
  ShrutiKoli,
  ShubhiPorwal,
  ReenaBadoliya,
  AdarshSingh,
  RamAvtarChauhan,
  avanee,
  RanveerSinghTomar,
} from "../../imports/ImageImports";

const TeamList = () => {
  const [responsive1, setResponsive1] = useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  });

  useEffect(() => {
    // Move data fetching code or side effects here if needed

    // Example: Update responsive based on data
    const updatedResponsive1 = {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    };

    setResponsive1(updatedResponsive1);
  }, []);

  const teamData = [
    {
      name: "Kumar Sanjeev",
      linkedIn: "https://www.linkedin.com/in/kumar-sanjeev-014809220/",
      designation: "Group CEO",
      image: sanjeev,
    },
    {
      name: "Haresh Sorathia",
      linkedIn: "https://www.linkedin.com/in/haresh-sorathia-730aa7209/",
      designation: "CFO",
      image: haresh,
    },
    {
      name: "Sunil Garad",
      linkedIn: "https://www.linkedin.com/in/sunil-garad-74a390125/",
      designation: "Senior Project Head",
      image: sunil,
    },
    {
      name: "Atul Naik",
      linkedIn: "https://www.linkedin.com/in/atul-naik-45a1a619a/",
      designation: "Senior Software Developer",
      image: atulNaik,
    },
    {
      name: "Runali Deb Roy",
      linkedIn: "https://www.linkedin.com/in/runali-deb-roy-881355188/",
      designation: "Assistant Project Manager",
      image: runali,
    },
    {
      name: "Riyaz sayyed",
      linkedIn: "https://www.linkedin.com/in/riyaz-sayyed-415042181/",
      designation: "Global Business Manager",
      image: riyaz,
    },
    {
      name: "Shruti Koli",
      linkedIn: "https://www.linkedin.com/in/shruti-koli-838280177/",
      designation: "Senior Accountant",
      image: ShrutiKoli,
    },
    {
      name: "Shubhi Porwal",
      linkedIn: "https://www.linkedin.com/in/shubhi-porwal-jain-ab6bb1224/",
      designation: "Business Analyst",
      image: ShubhiPorwal,
    },
    {
      name: "Pooja Sondhiya",
      linkedIn: "https://www.linkedin.com/in/pooja-sondhiya-473888159/",
      designation: "Chief Operating Officer",
      image: pooja,
    },
    {
      name: "Adarsh Singh",
      linkedIn: "https://www.linkedin.com/in/adarshsingh6026/",
      designation: " React Developer",
      image: AdarshSingh,
    },
    {
      name: "Ram Avtar Chauhan",
      linkedIn: "https://www.linkedin.com/in/ram-avtar-chauhan-7451221a6/",
      designation: " Flutter Developer",
      image: RamAvtarChauhan,
    },
    {
      name: "Reena Badoliya",
      linkedIn: "https://www.linkedin.com/in/reena-badoliya-0ab77a257/",
      designation: "Assistant HR Manager",
      image: ReenaBadoliya,
    },
    {
      name: "Avanee Shukla",
      linkedIn: "https://www.linkedin.com/in/avanee-shukla-7837b7233/",
      designation: "Assistant Project Manager",
      image: avanee,
    },
    {
      name: "Ranveer Singh Tomar",
      linkedIn: "https://www.linkedin.com/in/ranveer-singh-tomar-0896961b4/",
      designation: "   Junior Developer",
      image: RanveerSinghTomar,
    },
  ];

  return (
    <>
      <section className="featured-section bg-white">
        <div className="container py-3">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2 className="title">
                  The Faces Driving Our{" "}
                  <span className="text-danger">Vision Forward</span>
                </h2>
              </div>
            </div>
          </div>
          <OwlCarousel
            items={4}
            className="owl-theme fetured_slide owl-loaded owl-drag"
            margin={10}
            dots={true}
            autoplay={true}
            autoplayHoverPause={false}
            autoplayTimeout={2000}
            smartSpeed={1000}
            loop
            responsive={responsive1}
          >
            {teamData.map((item, index) => (
              <div className="item" key={index + 1}>
                <div className="mb-5 p-3">
                  <div className="mt-0  text-center">
                    <div
                      className="profile-images"
                      style={{
                        width: "200px",
                        height: "200px",
                        margin: "0 auto",
                      }}
                    >
                      <img
                        src={item.image}
                        alt="instructor"
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                      />
                    </div>

                    <div className="profile-content">
                      <div>
                        <h5 className="name mt-4 fw-bold mb-1">
                          {item.name ? item.name : ""}
                        </h5>
                      </div>
                      <p className="mb-1 text-nowrap">
                        {item.designation ? item.designation : ""}
                      </p>
                      <a
                        href={item.linkedIn ? item.linkedIn : "#"}
                        style={{ width: "70px", height: "40px" }}
                      >
                        <i className="ri-linkedin-box-fill fs-4 text-primary me-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default TeamList;

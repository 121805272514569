import React from "react";
import KeyFeatures from "../../components/core/key-features/KeyFeatures";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import CareerInfoBlock from "../../components/page-wise-components/career/CareerInfoBlock";
import CareerQualitySection from "../../components/page-wise-components/career/CareerQualitySection";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import Loading from "../../components/global/Loading";

const getBanner = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "career/banner", {
      signal,
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getAreYouABeliever = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "career/are-you-beliver",
      { signal }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getAdvantagesData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "career/advantages", {
      signal,
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getWhyChooseUsData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "career/why-join-us", {
      signal,
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getMetaData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "career/meta-details",
      { signal }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const Career = () => {
  const {
    data: headerBannerData,
    // error,
    isLoading: careerBannerLoading,
  } = useQuery("careerBannerData", getBanner, {
    staleTime: 60000, // Cache data for 60 seconds
    cacheTime: 300000, // Cache data for 5 minutes
    retry: 1, // Retry the request if it fails
    refetchOnWindowFocus: false, // Disable refetching on window focus
    onError: (err) => {
      if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
      } else {
        console.error(err);
      }
    },
  });
  const { data: metaData } = useQuery("metaData", getMetaData, {
    cacheTime: 0,
  });
  const { data: areYouBeliver, isLoading: careerAreYouBeliverLoading } =
    useQuery("getAreYouABeliever", getAreYouABeliever, {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    });
  const { data: advantages, isLoading: careerAdvantagesLoading } = useQuery(
    "getAdvantagesData",
    getAdvantagesData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );
  const { data: whyChooseUs, isLoading: careerWhyChooseUsLoading } = useQuery(
    "getWhyChooseUsData",
    getWhyChooseUsData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  if (
    careerBannerLoading ||
    careerAreYouBeliverLoading ||
    careerAdvantagesLoading ||
    careerWhyChooseUsLoading
  )
    return <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>
      {headerBannerData ? (
        <HeaderBanner
          imgUrl={headerBannerData.bannerImage}
          subtitle={headerBannerData.bannerHeading}
          title={headerBannerData.bannerSubHeading}
          description={headerBannerData.bannerDescription}
          buttonsOne={headerBannerData.buttonNameOne}
          buttonsLinkOne={headerBannerData.buttonLinkOne}
          buttonsTwo={headerBannerData.buttonNameTwo}
          buttonsLinkTwo={headerBannerData.buttonLinkTwo}
        />
      ) : (
        ""
      )}
      {areYouBeliver ? <CareerInfoBlock data={areYouBeliver} /> : ""}
      {advantages ? <CareerQualitySection data={advantages} /> : ""}
      {whyChooseUs ? (
        <KeyFeatures
          pageTitle={"Why Join Us"}
          // pageSubTitle={"Why Join Us"}
          content={whyChooseUs}
          customClass="col-md-4"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Career;

import React, { useEffect, useState } from "react";
import logo from "../../assets/website/image/logo.png";
import logomob from "../../assets/website/image/mob-logo.png";
import shoppingBagLogo from "../../assets/website/image/shopping-bag.png";
import googlePlayLogo from "../../assets/website/image/Storebadge2.png";
import appStoreLogo from "../../assets/website/image/Storebadge1.png";
import { Link, useLocation } from "react-router-dom";
import { useCart } from "react-use-cart";
import { ERPLINK, FACULTYLINK, STUDENTLINK } from "../../constants";
import axios from "axios";
import { useRef } from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SupportIcon from "@mui/icons-material/Support";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import hover Categories compnent
// import Categories from "../page-wise-components/HoverCategories/data.json";
import { RiArrowRightSLine } from "react-icons/ri";

import { useQuery } from "react-query";
import OffCanvas from "./OffCanvas";
import { upperCase } from "lodash";
import TranslateComponent from "./TranslateComponent";
import Cookies from "js-cookie";

const getExploreCourse = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "menu/explore", {
      signal, // Pass the signal to axios
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

function Navbar(props) {
  // Accessing the webengage variable
  // const webengage = window.webengage;

  const { data: exploreCourse } = useQuery(
    "exploreCourseData",
    getExploreCourse,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  const {
    isEmpty,
    totalItems,
    totalUniqueItems,
    items,
    cartTotal,
    inCart,
    removeItem,
    emptyCart,
  } = useCart();

  // Hover Categoriers section start
  const [showMainCategories, setShowMainCategories] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoveredSubcategory, setHoveredSubcategory] = useState(null);
  const [isSubcategoryHovered, setIsSubcategoryHovered] = useState(false);
  const [searchFilterValue, setSearchFilterValue] = useState();

  const timeoutRef = useRef(null);

  const handleH1MouseEnter = () => {
    setShowMainCategories(true);
  };

  const handleListHover = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleListLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredCategory(null);
      setHoveredSubcategory(null);
      setIsSubcategoryHovered(false);
      setShowMainCategories(false);
    }, 800); // Adjust the delay time as needed
  };

  const handleLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowMainCategories(false);
      setHoveredCategory(null);
      setHoveredSubcategory(null);
    }, 800);
  };

  const handleMouseEnter = (category, subcategory = null) => {
    setHoveredCategory(category);
    setHoveredSubcategory(subcategory);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      if (!isSubcategoryHovered) {
        // setHoveredCategory(null);
        // setHoveredSubcategory(null);
      }
    }, 800);
  };

  const handleSubcategoryMouseEnter = () => {
    setIsSubcategoryHovered(true);
  };

  const handleSubcategoryMouseLeave = () => {
    setIsSubcategoryHovered(false);
    if (!hoveredSubcategory) {
      setHoveredCategory(null);
    }
  };

  const searchParams = new URLSearchParams(window.location.search); //getdata for edit blog
  const querySearch = searchParams.get("q") ? searchParams.get("q") : "";

  useEffect(() => {
    const handleClickOutside = () => {
      setHoveredCategory(null);
      setHoveredSubcategory(null);
      setShowMainCategories(false);
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSearchFilterValue(
      window.location.pathname.includes(`/filter`) ? querySearch : ``
    );
  }, [querySearch]);

  const handleChange = (event) => {
    setSearchFilterValue(event.target.value);
  };

  // Hover Categoriers section end

  const location = useLocation();
  const isPlanPricingActive =
    location.pathname === "/cosmos-iq-business/plan-pricing";
  const isRequestDemoActive = location.pathname === "/request-demo";
  const isEnterpriseActive = location.pathname === "/cosmos-iq-business";
  const isGovernmentActive = location.pathname === "/teach-on-cosmos-iq";
  const isAboutUsActive = location.pathname === "/about-us";
  const isContactUsActive = location.pathname === "/contact-us";
  const isBlogsActive = location.pathname === "/blogs";

  const [profileData, setProfileData] = useState([]);
  // Check if the string exists within the component
  const stringExists = localStorage.getItem("authUserType")
    ? localStorage.getItem("authUserType").includes("instructor")
    : null;

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    // using localstrong
    // if (
    //   localStorage.getItem("authUser") &&
    //   localStorage.getItem("authUserType")
    // ) {
    //   await axios
    //     .get(window.apiBaseUrl + "auth/profile", {
    //       headers: {
    //         AccessToken: "Bearer " + localStorage.getItem("authUser"),
    //       },
    //     })
    //     .then((res) => {
    //       if (res.status === 200) {
    //         setProfileData(res.data.data);
    //         if (res.data.data.userEmpId) {
    //           window.webengage.user.login(res.data.data.userEmpId);
    //           window.webengage.user.setAttribute(
    //             "Role",
    //             res.data.data.userType
    //           );
    //         }
    //         if (res.data.data.emailId) {
    //           window.webengage.user.setAttribute(
    //             "we_email",
    //             res.data.data.emailId
    //           );
    //           window.webengage.user.setAttribute("we_email_opt_in", true);
    //         }
    //         if (res.data.data.mobileNumber) {
    //           window.webengage.user.setAttribute(
    //             "we_phone",
    //             res.data.data.countryCode + res.data.data.mobileNumber
    //           );
    //           window.webengage.user.setAttribute("we_sms_opt_in", true);
    //           window.webengage.user.setAttribute("we_whatsapp_opt_in", true);
    //         }
    //         if (res.data.data.w_dateOfBirth) {
    //           window.webengage.user.setAttribute(
    //             "we_birth_date",
    //             res.data.data.w_dateOfBirth
    //           );
    //         }
    //         if (res.data.data.gender) {
    //           window.webengage.user.setAttribute(
    //             "we_gender",
    //             res.data.data.gender
    //           );
    //         }
    //         if (res.data.data.fullName) {
    //           window.webengage.user.setAttribute(
    //             "we_first_name",
    //             res.data.data.fullName
    //           );
    //         }

    //         if (
    //           res.data.data.fullName &&
    //           res.data.data.w_dateOfBirth &&
    //           res.data.data.mobileNumber &&
    //           res.data.data.gender &&
    //           res.data.data.emailId &&
    //           res.data.data.userType
    //         ) {
    //           window.webengage.track("Profile Update", {
    //             "Full Name": res.data.data.fullName,
    //             "Date Of Birth": res.data.data.w_dateOfBirth,
    //             "Mobile Number": res.data.data.mobileNumber,
    //             Gender: res.data.data.gender,
    //             Email: res.data.data.emailId,
    //             Role: res.data.data.userType,
    //           });
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       // toast.error(err.response.data.message, {
    //       //   autoClose: 5000,
    //       //   theme: "colored",
    //       // });
    //     });
    // } else {
    //   window.webengage.user.logout();
    // }
    // using cookie
    if (Cookies.get("authUser") && Cookies.get("authUserType")) {
      await axios
        .get(window.apiBaseUrl + "auth/profile", {
          headers: {
            AccessToken: "Bearer " + Cookies.get("authUser"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setProfileData(res.data.data);
            if (res.data.data.userEmpId) {
              window.webengage.user.login(res.data.data.userEmpId);
              window.webengage.user.setAttribute(
                "Role",
                res.data.data.userType
              );
            }
            if (res.data.data.emailId) {
              window.webengage.user.setAttribute(
                "we_email",
                res.data.data.emailId
              );
              window.webengage.user.setAttribute("we_email_opt_in", true);
            }
            if (res.data.data.mobileNumber) {
              window.webengage.user.setAttribute(
                "we_phone",
                res.data.data.countryCode + res.data.data.mobileNumber
              );
              window.webengage.user.setAttribute("we_sms_opt_in", true);
              window.webengage.user.setAttribute("we_whatsapp_opt_in", true);
            }
            if (res.data.data.w_dateOfBirth) {
              window.webengage.user.setAttribute(
                "we_birth_date",
                res.data.data.w_dateOfBirth
              );
            }
            if (res.data.data.gender) {
              window.webengage.user.setAttribute(
                "we_gender",
                res.data.data.gender
              );
            }
            if (res.data.data.fullName) {
              window.webengage.user.setAttribute(
                "we_first_name",
                res.data.data.fullName
              );
            }

            if (
              res.data.data.fullName &&
              res.data.data.w_dateOfBirth &&
              res.data.data.mobileNumber &&
              res.data.data.gender &&
              res.data.data.emailId &&
              res.data.data.userType
            ) {
              window.webengage.track("Profile Update", {
                "Full Name": res.data.data.fullName,
                "Date Of Birth": res.data.data.w_dateOfBirth,
                "Mobile Number": res.data.data.mobileNumber,
                Gender: res.data.data.gender,
                Email: res.data.data.emailId,
                Role: res.data.data.userType,
              });
            }
          }
        })
        .catch((err) => {
          // toast.error(err.response.data.message, {
          //   autoClose: 5000,
          //   theme: "colored",
          // });
        });
    } else {
      window.webengage.user.logout();
    }
  };

  useEffect(() => {
    if (inCart) {
      const cartItems = {
        items: items,
        isEmpty: isEmpty,
        totalItems: totalItems,
        totalUniqueItems: totalUniqueItems,
        cartTotal: cartTotal,
      };
      const iframe = document.querySelector("iframe");
      const wind = iframe.contentWindow;
      const data = { cartItems };
      wind.postMessage(data, "*");
    }
  }, [inCart]);

  // if (isLoading) return <div>Fetching posts...</div>;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      {/* Header Start */}
      <div id="header" className="header section d-flex align-items-center">
        {/* <div className="container"> */}
        {/* Header Wrapper Start */}
        <div className="header-wrapper px-3 w-100">
          {/* Header Logo Start */}
          <div className="header-logo">
            <Link to="/">
              <img src={logo} alt="logo" className="desktop-logo" />
              <img src={logomob} alt="logoicon" className="desktop-mobile" />
            </Link>
            {window.location.pathname.includes("/cosmos-iq-business") ? null : (
              <div className="header-menu d-lg-block align-items-center ">
                <ul className="main-menu result">
                  <li>
                    <div className="arrow-icon d-lg-block d-none ">
                      <Link
                        className="active main-linking "
                        to="/"
                        onMouseEnter={handleH1MouseEnter}
                        onMouseLeave={handleLeave}
                      >
                        <img
                          src="https://www.matriyeacademy.com/user-assets/images/icon-new/category-icon.svg"
                          alt="Cart"
                          // loading="lazy"
                        />
                        Explore Courses
                        <span className="menu-icon d-lg-block d-none"></span>
                      </Link>
                    </div>
                    <div
                      style={{ top: "100%" }}
                      className="hover-main-category position-absolute d-md-flex d-none"
                    >
                      {showMainCategories && (
                        <ul
                          onMouseEnter={handleListHover}
                          onMouseLeave={handleListLeave}
                          style={{ minWidth: "250px" }}
                          className="bg-white border "
                        >
                          {exploreCourse ? (
                            <>
                              {Object.keys(exploreCourse).map(
                                (category, index) => (
                                  <li
                                    key={index}
                                    className={`hover-list m-1 py-2 d-flex justify-content-between ${
                                      hoveredCategory === category
                                        ? "text-danger cursor-pointer"
                                        : ""
                                    }`}
                                    onMouseEnter={() =>
                                      handleMouseEnter(category)
                                    }
                                    // onMouseLeave={handleMouseLeave}
                                  >
                                    <Link
                                      to={`/filter?q=${category
                                        .toLowerCase()
                                        .replace(/\s+/g, "+")}`}
                                    >
                                      {category}
                                    </Link>{" "}
                                    <RiArrowRightSLine />
                                  </li>
                                )
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </ul>
                      )}

                      {hoveredCategory && (
                        <ul
                          onMouseEnter={handleListHover}
                          onMouseLeave={handleListLeave}
                          style={{ minWidth: "250px" }}
                          className="bg-white border "
                        >
                          {exploreCourse ? (
                            <>
                              {Object.keys(exploreCourse[hoveredCategory]).map(
                                (subcategory, subIndex) => (
                                  <li
                                    key={subIndex}
                                    className={`hover-list my-1  py-2  d-flex justify-content-between ${
                                      hoveredSubcategory === subcategory
                                        ? "text-danger"
                                        : ""
                                    }`}
                                    onMouseEnter={() =>
                                      handleMouseEnter(
                                        hoveredCategory,
                                        subcategory
                                      )
                                    }
                                    onMouseLeave={handleMouseLeave}
                                    onMouseOver={handleSubcategoryMouseEnter}
                                    onMouseOut={handleSubcategoryMouseLeave}
                                  >
                                    <Link
                                      to={`/filter?q=${subcategory
                                        .toLowerCase()
                                        .replace(/\s+/g, "+")}`}
                                    >
                                      <span className="w-75">
                                        {subcategory}
                                      </span>
                                    </Link>
                                    <RiArrowRightSLine className="float-end" />
                                  </li>
                                )
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </ul>
                      )}

                      {hoveredSubcategory && (
                        <div
                          onMouseEnter={handleListHover}
                          onMouseLeave={handleListLeave}
                          className="bg-white border p-2"
                          style={{ minWidth: "250px" }}
                        >
                          {/* <h2 className="fs-6 p-2 w-75 text-secondary">{hoveredSubcategory}</h2> */}
                          <h2 className="fs-6 p-2 w-75 text-secondary">
                            Popular
                          </h2>
                          <ul className="bg-whit">
                            {exploreCourse[hoveredCategory][
                              hoveredSubcategory
                            ].map((item, itemIndex) => (
                              <li
                                key={itemIndex}
                                className={`hover-list m-1 px-2 py-2  ${
                                  hoveredSubcategory === item
                                    ? "text-primary"
                                    : ""
                                }`}
                              >
                                <Link
                                  to={`/filter?q=${item
                                    .toLowerCase()
                                    .replace(/\s+/g, "+")}`}
                                >
                                  {item}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </li>
                  <div className="header-search web-search d-md-block d-none mb-2">
                    <form action="/filter" method="get">
                      <input
                        type="text"
                        name="q"
                        placeholder="What do you want to learn?"
                        value={searchFilterValue || ""}
                        onChange={handleChange}
                      />
                      <button type="submit">
                        <i className="flaticon-loupe text-secondary"></i>
                      </button>
                    </form>
                  </div>
                </ul>
              </div>
            )}
            <div className="hover-div-menu"></div>
          </div>
          {/* Header Logo End */}
          {/* Header Meta Start */}
          <div className="header-meta">
            <div className="header-menu d-none d-lg-block">
              <ul className="main-menu">
                <li>
                  <Link
                    to="/cosmos-iq-business"
                    className={isEnterpriseActive ? "active" : ""}
                    style={{ fontSize: "0.8rem" }}
                  >
                    Cosmos IQ Business
                  </Link>
                </li>
                {window.location.pathname.includes("/cosmos-iq-business") ? (
                  <>
                    <li>
                      <Link
                        to="/cosmos-iq-business/plan-pricing"
                        className={isPlanPricingActive ? "active" : ""}
                        style={{ fontSize: "0.8rem" }}
                      >
                        Plan and pricing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cosmos-iq-business/request-demo?featureName=marketing-erp"
                        className={isRequestDemoActive ? "active" : ""}
                        style={{ fontSize: "0.8rem" }}
                      >
                        Request a Demo
                      </Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link
                      to="/teach-on-cosmos-iq"
                      className={isGovernmentActive ? "active" : ""}
                      style={{ fontSize: "0.8rem" }}
                    >
                      Teach on Cosmos IQ
                    </Link>
                  </li>
                )}
                <TranslateComponent />
                {/* <li className="select-lang">
                  <a className="link btn btn-primary" href="/">
                    <i className="fa fa-globe" aria-hidden="true"></i>
                  </a>
                </li> */}
              </ul>
            </div>
            {stringExists ? (
              ""
            ) : (
              <div className="header-cart dropdown px-0">
                <button className="cart-btn" data-bs-toggle="dropdown">
                  <img
                    src={shoppingBagLogo}
                    className="shopping"
                    alt="shopping bag"
                  />
                  <span className="count">{totalUniqueItems}</span>
                </button>
                <div className="dropdown-menu dropdown-cart">
                  {isEmpty ? (
                    <>
                      <div className="cart-total">
                        <span className="label">Your cart is empty</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="cart-items">
                        {items.map((item) => (
                          <div key={item.id} className="single-cart-item">
                            <div className="item-image">
                              <img src={item.image} alt="Cart" loading="lazy" />
                            </div>
                            <div className="item-content">
                              <h4 className="title">
                                <Link to="/">
                                  {item.subjectName}
                                  <br />
                                  <small>{item.courseName}</small>
                                </Link>
                              </h4>
                              <span className="quantity">
                                {" "}
                                Rs. {item.price}
                              </span>
                            </div>
                            <Link to="/" className="text-danger">
                              <button
                                className="btn-close"
                                onClick={() => removeItem(item.id)}
                              ></button>
                            </Link>
                          </div>
                        ))}
                      </div>
                      <div className="cart-total">
                        <span className="label">Subtotal:</span>
                        <span className="">{cartTotal}</span>
                      </div>
                      <div className="cart-btns">
                        <Link
                          to="/cart"
                          className="btn btn-white btn-hover-primary"
                        >
                          Cart Details
                        </Link>
                        <button
                          onClick={emptyCart}
                          className="btn btn-white btn-hover-danger"
                        >
                          <i className="fa fa-trash"></i>&nbsp; Clear Cart
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {profileData && profileData.fullName ? (
              <div className="header-login d-lg-flex">
                <div className="dropdown">
                  <button
                    className="dropdown-toggles author"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      width: "45px",
                      height: "45px",
                    }}
                  >
                    {profileData.profilePicture &&
                    profileData.profilePicture ? (
                      <img
                        src={profileData.profilePicture}
                        className="userlogo"
                        alt="user-profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <span>
                        <b>
                          {profileData.fullName
                            ? upperCase(profileData.fullName[0])
                            : "F"}
                        </b>
                      </span>
                    )}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="dropdown-inner">
                      <div className="user-profile-wrap">
                        Welcome&nbsp;
                        {profileData.fullName ? profileData.fullName : ""}
                      </div>
                      <ul>
                        {profileData.userType === "enterprise" ||
                        profileData.userType === "employee" ? (
                          <>
                            <li>
                              <Link to={ERPLINK + "/dashboard"}>
                                <i className="ri-dashboard-fill text-muted fs-16 align-middle me-1"></i>
                                Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link to={ERPLINK + "/transactions/payments"}>
                                <i className="ri-wallet-3-line text-muted fs-16 align-middle me-1"></i>
                                Transactions
                              </Link>
                            </li>
                            <li>
                              <Link to={ERPLINK + "/settlements"}>
                                <i className="ri-percent-line text-muted fs-16 align-middle me-1"></i>
                                Settlements
                              </Link>
                            </li>
                            <li>
                              <Link to={ERPLINK + "/reports"}>
                                <i className="ri-file-text-line text-muted fs-16 align-middle me-1"></i>
                                Reports
                              </Link>
                            </li>
                            <li>
                              <Link to={ERPLINK + "/account-settings"}>
                                <i className="ri-settings-4-line text-muted fs-16 align-middle me-1"></i>
                                Account & Settings
                              </Link>
                            </li>
                            <div className="dropdown-divider"></div>
                            <li>
                              <Link to={ERPLINK + "/help-and-support"}>
                                <i className="ri-lifebuoy-line text-muted fs-16 align-middle me-1"></i>
                                Help and Support
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                to={
                                  ERPLINK + "/logout"
                                }
                              >
                                <i className="ri-logout-box-r-line text-muted fs-16 align-middle me-1"></i>
                                Logout
                              </Link>
                            </li> */}
                          </>
                        ) : (
                          <>
                            <li>
                              <Link
                                to={`${
                                  stringExists ? FACULTYLINK : STUDENTLINK
                                }/dashboard`}
                              >
                                <DashboardIcon className="mui-icon me-2" />
                                Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`${
                                  stringExists ? FACULTYLINK : STUDENTLINK
                                }/profile`}
                              >
                                <AccountCircleIcon className="mui-icon me-2" />
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`${
                                  stringExists ? FACULTYLINK : STUDENTLINK
                                }/payment-history`}
                              >
                                <AccountBalanceWalletIcon className="mui-icon me-2" />
                                Payment History
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`${
                                  stringExists ? FACULTYLINK : STUDENTLINK
                                }/apply-for-leave`}
                              >
                                <EventAvailableIcon className="mui-icon me-2" />
                                Apply for leave
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`${
                                  stringExists ? FACULTYLINK : STUDENTLINK
                                }/help-and-support`}
                              >
                                <SupportIcon className="mui-icon me-2" />
                                Help and support
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                      {/* <ul>
                      <li>
                        <Link to={STUDENTLINK + "/logout"}>
                          <LogoutRoundedIcon className="mui-icon fs-5 me-2"/>
                          Log Out</Link>
                      </li>
                    </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="header-login d-flex desktop-login ">
                <Link
                  to={STUDENTLINK}
                  className="btn btn-outline-primary fw-semibold px-2 me-2"
                  style={{
                    fontSize: "13px",
                    display: "flex",
                    height: "0",
                    lineHeight: "0",
                    padding: "14px",
                  }}
                >
                  Login
                </Link>
                <Link
                  to={STUDENTLINK + "/register"}
                  className="btn btn-primary fw-semibold px-2"
                  style={{
                    fontSize: "13px",
                    display: "flex",
                    height: "0",
                    lineHeight: "0",
                    padding: "15px",
                  }}
                >
                  Join for Free
                </Link>
              </div>
            )}

            <div className="header-toggle d-lg-none">
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBothOptions"
                aria-controls="offcanvasWithBothOptions"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
          {/* Header Meta End */}
        </div>
        {/* </div> */}
      </div>
      {/* Header End */}
      {/*  */}
      {/* Offcanvas Start */}
      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabIndex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header">
          {/* Offcanvas Logo Start */}
          <div className="offcanvas-logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          {/* Offcanvas Logo End */}
          <button className="btn-close" data-bs-dismiss="offcanvas"></button>
        </div>
        <div className="offcanvas-body">
          <div className="header-search d-lg-block mb-2">
            <form action="/filter" method="get">
              <input
                type="text"
                name="q"
                placeholder="What do you want to learn?"
                value={searchFilterValue || ""}
                onChange={handleChange}
              />
              <button type="submit">
                <i className="flaticon-loupe"></i>
              </button>
            </form>
          </div>
          <div className="popular-courses position-relative">
            <OffCanvas btn={"Popular Course"} course={exploreCourse} />
          </div>
          <div className="offcanvas-menu">
            <ul className="main-menu">
              <li>
                <Link
                  to="/cosmos-iq-business"
                  className={isEnterpriseActive ? "active" : ""}
                >
                  Cosmos IQ Business
                </Link>
              </li>
              {window.location.pathname.includes("/cosmos-iq-business") ? (
                <>
                  <li data-bs-dismiss="offcanvas">
                    <Link
                      to="/cosmos-iq-business/plan-pricing"
                      className={isPlanPricingActive ? "active" : ""}
                    >
                      Plan and pricing
                    </Link>
                  </li>
                  <li data-bs-dismiss="offcanvas">
                    <Link
                      to="/cosmos-iq-business/request-demo?featureName=marketing-erp"
                      className={isRequestDemoActive ? "active" : ""}
                    >
                      Request a Demo
                    </Link>
                  </li>
                </>
              ) : (
                <li data-bs-dismiss="offcanvas">
                  <Link
                    to="/teach-on-cosmos-iq"
                    className={isGovernmentActive ? "active" : ""}
                  >
                    Teach on Cosmos IQ
                  </Link>
                </li>
              )}
              <li data-bs-dismiss="offcanvas">
                <Link
                  to="/about-us"
                  className={isAboutUsActive ? "active" : ""}
                >
                  About Us
                </Link>
              </li>
              <li data-bs-dismiss="offcanvas">
                <Link
                  to="/contact-us"
                  className={isContactUsActive ? "active" : ""}
                >
                  Contact Us
                </Link>
              </li>
              <li data-bs-dismiss="offcanvas">
                <Link to="/blogs" className={isBlogsActive ? "active" : ""}>
                  Blogs
                </Link>
              </li>
            </ul>
            <div className="app-download-offer">
              <a href="https://play.google.com/store/apps/details?id=com.cosmosiq.ai">
                <img src={googlePlayLogo} alt="google-play-store" />
              </a>
              <a href="https://apps.apple.com/us/app/cosmos-iq/id6480333541">
                <img src={appStoreLogo} alt="app-store" />
              </a>
              <div className="app-download"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Offcanvas End */}
    </>
  );
  // }
}

export default Navbar;

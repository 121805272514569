import React from "react";
import logo from "../../assets/website/image/logo-white.png";
import googlePlayLogo from "../../assets/website/image/Storebadge2.png";
import godaddySslLogo from "../../assets/website/image/godaddy-ssl.png";
import iso2015Logo from "../../assets/website/image/iso2015.webp";
import appStoreLogo from "../../assets/website/image/Storebadge1.png";
import studentAppLogo from "../../assets/website/image/student-app.png";
import instructorAppLogo from "../../assets/website/image/instructor-app.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { forNewsLetter } from "../../config/formValidation";
import { useFormik } from "formik";
import axios from "axios";
import { useQuery } from "react-query";

const getOrganization = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "static/organization",
      {
        signal, // Pass the signal to axios
      }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const FooterComponent = () => {
  const { data: organizationData } = useQuery(
    "organizationData",
    getOrganization,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );

  const currentYear = new Date().getFullYear();

  const {
    values,
    errors,
    dirty,
    isValid,
    isSubmitting,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forNewsLetter,
    onSubmit: async (values, action) => {
      await axios
        .post(window.apiBaseUrl + "news/update", values, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              autoClose: 5000,
              theme: "colored",
            });
            action.resetForm();
          } else {
            toast.warn(res.data.message, {
              autoClose: 5000,
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          // toast.error(err.response.data.message, {
          //   autoClose: 5000,
          //   theme: "colored",
          // });
        });
    },
  });

  return (
    <>
      {/* Main Footer Start */}
      <footer className="new-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="new-footer-logo">
                <div className="new-footer-logo-one">
                  <img src={logo} alt="logo" />
                  <p>
                    Cosmos IQ is India’s largest online learning platform, with
                    over 3,000+ courses taught by expert instructors. Get
                    started today by downloading our apps!
                  </p>
                  <a href="https://play.google.com/store/apps/details?id=com.cosmosiq.ai">
                    <img src={googlePlayLogo} alt="google-play" />
                  </a>
                  <a href="https://apps.apple.com/us/app/cosmos-iq/id6480333541">
                    <img src={appStoreLogo} alt="app-store" />
                  </a>
                </div>
                <div className="new-footer-logo-two new-footer-logo-two-mobile">
                  <h6>Reach out to us!</h6>
                  <p>
                    Have questions about studying at Cosmos IQ? Give us a call
                    and we'll be happy to answer them for you!
                  </p>
                  <a
                    href={`tel:+${
                      organizationData
                        ? organizationData.missedCallNumber
                        : "8956069932"
                    }`}
                  >
                    <p>
                      <i className="fa fa-phone"></i> +91{" "}
                      {organizationData
                        ? organizationData.missedCallNumber
                        : "8956069932"}
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-3">
              <div className="new-footer-link">
                <h6>Pages</h6>
                <ul className="list">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/cosmos-iq-business">Cosmos IQ Business</Link>
                  </li>
                  <li>
                    <Link to="/teach-on-cosmos-iq">Teach on Cosmos IQ</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-3">
              <div className="new-footer-link">
                <h6>Help</h6>
                <ul className="list">
                  <li>
                    <Link to="/help-faqs">Help/FAQS</Link>
                  </li>
                  <li>
                    <Link to="/notice-board">Notice Board</Link>
                  </li>
                  <li>
                    <Link to="/career">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-3">
              <div className="new-footer-link">
                <h6>Information</h6>
                <ul className="list">
                  <li>
                    <Link to="/terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/cookie-policy">Cookie</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-3">
              <div className="new-footer-link">
                <h6>Our Apps</h6>
                <ul className="list">
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.cosmosiq.ai">
                      <img src={studentAppLogo} alt="student-app" />
                      Student App
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.cosmosiq.ai.faculty">
                      <img src={instructorAppLogo} alt="instructor-app" />
                      Faculty App
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="new-footer-iews-outer">
            <div className="row">
              <div className="col-md-4">
                <div className="new-footer-logo-two new-footer-logo-two-desktop">
                  <h6>Reach out to us!</h6>
                  <p>
                    Have questions about studying at Cosmos IQ? Give us a call
                    and we'll be happy to answer them for you!
                  </p>
                  <a
                    href={`tel:+${
                      organizationData
                        ? organizationData.missedCallNumber
                        : "8956069932"
                    }`}
                  >
                    <p>
                      <i className="fa fa-phone"></i> +91{" "}
                      {organizationData
                        ? organizationData.missedCallNumber
                        : "8956069932"}
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-4 offset-md-4">
                <div className="new-footer-news">
                  <div className="subscribe-form mb-0">
                    <h6>Subscribe Newsletter</h6>
                    <form
                      className="form-horizontal"
                      id="userNewsletter"
                      name="userNewsletter"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p className="text-danger">{errors.email}</p>
                        ) : null}
                        <button
                          type="submit"
                          className="submit-btn submit"
                          disabled={!(dirty && isValid && !isSubmitting)}
                        >
                          {isSubmitting ? (
                            <span className="icon flaticon-right-arrow-2 text-white"></span>
                          ) : (
                            <span className="icon flaticon-right-arrow-2 text-white"></span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="new-footer-bottom ">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="new-footer-copyright">
                  <a href="/">
                    <p>
                      &#169;Copyright {currentYear}, Matriye Software Pvt Ltd.
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-5 text-center">
                <div className="certificate">
                  <img
                    src={godaddySslLogo}
                    className="network domain security, domain security check"
                    alt="godaddy-logi"
                  />
                  <img
                    src={iso2015Logo}
                    className="iso certified 9001, iso certified logo"
                    alt="iso-logo"
                  />
                  <a
                    href="https://www.dmca.com/Protection/Status.aspx?ID=6574f096-c526-4a35-94e7-6494f8af1909&amp;refurl=https://www.matriyeacademy.com/"
                    title="DMCA.com Protection Status"
                    className="dmca-badge"
                  >
                    {" "}
                    <img
                      src="https://images.dmca.com/Badges/_dmca_premi_badge_4.png?ID=6574f096-c526-4a35-94e7-6494f8af1909"
                      alt="DMCA.com Protection Status"
                    />
                  </a>
                  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
                    {" "}
                  </script>
                </div>
              </div>
              <div className="col-md-3 text-xl-right text-center">
                <div className="new-footer-social">
                  <ul className="social-box">
                    <li className="twitter">
                      <a
                        href={`${
                          organizationData
                            ? organizationData.twitterURL
                            : `https://x.com/MatriyeA`
                        }`}
                        target="_blank"
                      >
                        <i className="fa fa-twitter"> </i>
                      </a>
                    </li>
                    <li className="linkedin">
                      <a
                        href={`${
                          organizationData
                            ? organizationData.linkedinURL
                            : `https://www.linkedin.com/company/cosmos-iq-ai/`
                        }`}
                        target="_blank"
                      >
                        <i className="fa fa-linkedin"> </i>
                      </a>
                    </li>
                    <li className="facebook">
                      <a
                        href={`${
                          organizationData
                            ? organizationData.facebookURL
                            : `https://www.facebook.com/CosmosIQ/`
                        }`}
                        target="_blank"
                      >
                        <i className="fa fa-facebook-f"> </i>
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href={`${
                          organizationData
                            ? organizationData.instagramURL
                            : `https://www.facebook.com/CosmosIQ/`
                        }`}
                        target="_blank"
                      >
                        <i className="fa fa-instagram"> </i>
                      </a>
                    </li>
                    <li className="pinterest">
                      <a
                        href={`${
                          organizationData
                            ? organizationData.youtubeURL
                            : `https://www.youtube.com/channel/UC0xDyUBPcSoQMxWQWcdwjAg`
                        }`}
                        target="_blank"
                      >
                        <i className="fa fa-youtube"> </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Main Footer End */}
      <button className="back-btn" id="backBtn">
        <i className="fa fa-angle-up"></i>
      </button>
    </>
  );
};

export default FooterComponent;

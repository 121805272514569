import React, { useEffect, useState } from "react";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import axios from "axios";
import { useQuery } from "react-query";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import { toast } from "react-toastify";
import Loading from "../../components/global/Loading";

const getBannerData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "help-faqs/banner", {
      signal,
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

// const getFaqsListData = async ({ signal }) => {
// try {
//   const response = await axios.get(window.apiBaseUrl+'help-faqs/faqs-list',{ signal });
//   return response.data.data;
// } catch (error) {
//   if (axios.isCancel(error)) {
//     console.log("Request canceled:", error.message);
//   } else {
//     throw error; // Re-throw if not canceled
//   }
// }
// };

const getMetaData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "help-faqs/meta-details",
      { signal }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const HelpFaqs = () => {
  const {
    data: headerBannerData,
    // error,
    isLoading: helpFaqsBannerLoading,
  } = useQuery("helpFaqsBannerData", getBannerData, {
    staleTime: 60000, // Cache data for 60 seconds
    cacheTime: 300000, // Cache data for 5 minutes
    retry: 1, // Retry the request if it fails
    refetchOnWindowFocus: false, // Disable refetching on window focus
    onError: (err) => {
      if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
      } else {
        console.error(err);
      }
    },
  });
  const { data: metaData } = useQuery("metaData", getMetaData, {
    cacheTime: 0,
  });
  // const { data: faqsList } = useQuery("faqsList", getFaqsListData);
  const [faqsList, setData] = useState([]);
  const [ToggleState, setToggleState] = useState(1);

  const tabList = ["student", "teach on cosmos iq", "cosmos iq business"];

  let changeUrl = "/help-faqs/faqs-list?faqUser=student";

  const toggleTab = (index, value) => {
    setToggleState(index);
    changeUrl = value ? "/help-faqs/faqs-list?faqUser=" + value : "";
    getData(changeUrl);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  useEffect(() => {
    if (changeUrl) {
      getData(changeUrl);
    }
  }, [changeUrl]);

  const getData = async (value) => {
    await axios
      .get(window.apiBaseUrl + value)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  if (helpFaqsBannerLoading) return <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>
      {headerBannerData ? (
        <HeaderBanner
          imgUrl={headerBannerData.bannerImage}
          subtitle={headerBannerData.bannerHeading}
          title={headerBannerData.bannerSubHeading}
          description={headerBannerData.bannerDescription}
          buttonsOne={headerBannerData.buttonNameOne}
          buttonsLinkOne={headerBannerData.buttonLinkOne}
          buttonsTwo={headerBannerData.buttonNameTwo}
          buttonsLinkTwo={headerBannerData.buttonLinkTwo}
        />
      ) : (
        ""
      )}

      <section className="faq-tabs">
        <div className="container">
          <ul className="nav nav-tabs">
            {tabList.map((key, index) => (
              <li key={index + 1} className="nav-item">
                <a
                  className={`nav-link ${getActiveClass(index + 1, "active")}`}
                  onClick={() => toggleTab(index + 1, key)}
                  id={`${key}-tab`}
                  data-toggle="pill"
                  href={`#${key}`}
                  data-value={key}
                  role="tab"
                  aria-controls={`${key}`}
                  aria-selected="true"
                >
                  {key}
                </a>
              </li>
            ))}
          </ul>

          <div className="tab-content">
            {tabList.map((key, index) => (
              <div
                key={index + 1}
                className={`tab-pane container ${getActiveClass(
                  index + 1,
                  "active"
                )}`}
                id={`${key}`}
                role="tabpanel"
                aria-labelledby={`${key}-tab`}
              >
                <section className="terms-qutaion pt-5 pb-5">
                  <div className="container">
                    <Accordion defaultActiveKey="0">
                      {faqsList.map((faqs, index) => (
                        <Accordion.Item key={index + 1} eventKey={index + 1}>
                          <Accordion.Header>{faqs.question}</Accordion.Header>
                          <Accordion.Body>
                            <div
                              dangerouslySetInnerHTML={{ __html: faqs.answer }}
                            ></div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </section>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpFaqs;

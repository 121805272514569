import React from "react";
import jsonData from "./CareerDetalisData.json";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import JobProfiles from "../../components/page-wise-components/career/JobProfiles";
import CareerRelatedKnowledge from "../../components/page-wise-components/career/CareerRelatedKnowledge.jsx";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants.js";

const getCareerDetailsData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "career/career-solutions",
      { signal }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getJobListData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "career/job-list", {
      signal,
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const CareerDetails = () => {
  const careerDeailsdata = jsonData[1];

  const {
    data: jobList,
    // error,
    isLoading,
  } = useQuery("jobListData", getJobListData, {
    staleTime: 60000, // Cache data for 60 seconds
    cacheTime: 300000, // Cache data for 5 minutes
    retry: 1, // Retry the request if it fails
    refetchOnWindowFocus: false, // Disable refetching on window focus
    onError: (err) => {
      if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
      } else {
        console.error(err);
      }
    },
  });
  const { data: careerDetails } = useQuery(
    "careerDetails",
    getCareerDetailsData,
    {
      staleTime: 60000, // Cache data for 60 seconds
      cacheTime: 300000, // Cache data for 5 minutes
      retry: 1, // Retry the request if it fails
      refetchOnWindowFocus: false, // Disable refetching on window focus
      onError: (err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error(err);
        }
      },
    }
  );
  const careerDetailsData = careerDetails;
  const jobListData = jobList;

  if (isLoading) return <div>Fetching posts...</div>;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{SITETITLE} :: Career</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      {careerDeailsdata ? (
        <HeaderBanner
          imgUrl={careerDeailsdata.imgUrl}
          subtitle={careerDeailsdata.subtitle}
          title={careerDeailsdata.title}
          description={careerDeailsdata.description}
        />
      ) : (
        ""
      )}
      <JobProfiles data={jobListData} />
      <CareerRelatedKnowledge data={careerDetailsData} />
    </>
  );
};

export default CareerDetails;
